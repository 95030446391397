import React from "react";

function Footer() {
  return (
    <div className="Footer" id="contact">
      <div className="container">
      <div className="contact-heading"><h2>Contact US</h2></div>
        <div className="row">
          <div className="col-md-3 col-12">
            <img src="Assets/logo.png" className="footer-image" alt="" />
          </div>
          <div className="footer-style col-md-8 col-12">
            <p className="goto-link">
              <b>Email : </b>{" "}
              <a href="mailto:  globalitsolutionllc@gmail.com">globalitsolutionllc@gmail.com</a>
            </p>
            <p className="goto-link">
              <b>Phone no : </b> <a href="tel:+17039443380">(800) 224-8260</a>
            </p>
            <p>
              <b>Address : </b>9300 Peabody St Unit 204 Manassas, VA 20110, USA
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
