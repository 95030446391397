import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './Components/Layout/Header';
import Hero from './Components/Elements/Hero';
import Software from './Components/Elements/Software';
import Application from './Components/Elements/Application';
import Footer from './Components/Layout/Footer';
import Aboutus from './Components/Elements/Aboutus';
import Portfolio from './Components/Elements/Portfolio';

function App() {
  return (
    <div>
      <Header/>
      <Hero/>
      <Software/>
      <Application/>
      <Portfolio/>
      <Aboutus/>
      <Footer/>
    </div>
  );
}

export default App;
