import React from "react";

function Aboutus() {
  return (
    <div className="About-us" id="about">
      <div className="container">
        <div className="about-heading">
          <h1><b>About US</b></h1>
        </div>
        <div className="about-text">
          <h2>About Global IT Solutions</h2>
          <p>
            At Global IT Solutions, we believe in the power of technology to
            transform businesses and drive innovation. Established with a vision
            to provide comprehensive IT solutions to organizations worldwide, we
            have been at the forefront of delivering cutting-edge technology
            services since our inception.
          </p>
        </div>
        <div className="about-text">
          <h2>Our Mission</h2>
          <p>
            Our mission is to empower businesses of all sizes with tailor-made
            IT solutions that enhance efficiency, foster growth, and ensure
            sustainability in today’s dynamic digital landscape. We are
            committed to leveraging the latest advancements in technology to
            address the evolving needs of our clients and help them achieve
            their strategic objectives.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
