import React from "react";

function Application() {
  return (
    <div className="Application">
      <div className="container">
        <div className="row section1">
          <div className="col-md-6 col-12">
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev5.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>Custom Application Development</h5>
                <p>
                  We rely on our industry-specific technology experience to
                  deliver highly scalable, flexible, and interoperable web,
                  mobile, desktop, and hybrid applications.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev6.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>Application Maintenance</h5>
                <p>
                  Our app maintenance & modernization services are designed to
                  ensure the scalability, performance, and sustainability of
                  your entire software infrastructure.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img className="img-overflow" src="Assets/soft-dev7.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>API Integrations</h5>
                <p>
                  We build custom APIs for all breeds of applications, adding
                  functionality to your software systems and facilitating
                  communication between your apps & others.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev8.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>UX/UI Design</h5>
                <p>
                  Leverage the latest UI/UX technologies, architectures, and
                  trends to design responsive & scalable apps that transform
                  customer experiences across multiple channels.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 pic2">
            <img src="Assets/pic2.png" className="sec2-img" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Application;
