import React from "react";

function Software() {
  return (
    <div className="Software">
      <div className="center-heading">
        <div className="hero-heading">
          <h2>
            <b>CUSTOM SOFTWARE DEVELOPMENT SERVICES</b>
          </h2>
          <h5>
            <b>
              Transforming Industries with AI-Powered Custom Software Solutions
            </b>
          </h5>
        </div>
      </div>
      <div className="container">
        <div className="row section1">
          <div className="col-md-6 col-12">
            <img src="Assets/pic1.png" className="sec1-img" alt="" />
          </div>
          <div className="col-md-6 col-12">
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev1.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>Custom Software Development</h5>
                <p>
                  Our custom web development solutions accelerate workflows,
                  boost revenues, and optimize business operations from
                  concept-to-code and development-to-deployment.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev2.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>Implementation & Deployment</h5>
                <p>
                  We devise an in-depth, comprehensive development process
                  including software implementation & deployment plan, assessing
                  your needs to deliver an enhanced user experience for
                  end-users.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img src="Assets/soft-dev3.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>API Development</h5>
                <p>
                  We develop reliable, well-documented, and easy-to-consume APIs
                  that enable flexible integrations and customization of
                  existing software products.
                </p>
              </div>
            </div>
            <div className="sub-heading">
              <div>
                <img className="img-overflow"  src="Assets/soft-dev4.svg" alt="" />
              </div>
              <div className="sub-heading1">
                <h5>System Integrations</h5>
                <p>
                  Our software integration engineers adopt new technologies and
                  processes to overcome challenges relating to everything from
                  architectural design to testing to execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Software;
