import React from "react";

function Portfolio() {
  return (
    <div className="Portfolio " id="port">
      <div className="container">
        <div className="portfolio-heading">
          <h1><b>Our Work</b></h1>
        </div>
        <div className="row">
        <a href="https://www.instagram.com/bachelorpoint.pk?igsh=MXJ6MTA1aTAwaDg1dA==" className="col-md-4 card-center col-12" target="_blank" rel="noopener noreferrer">
        <div >
          <div className="card" style={{ width: "18rem" }}>
            <img
              src="Assets/portfolio1.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body card-1">
              <h4 className="card-text">
                Social Media Branding and E-commerce
              </h4>
            </div>
          </div>
        </div>
      </a>
      
      <a href="https://play.google.com/store/apps/details?id=com.jkapps.grete.greeting.card.maker" target="_blank" className="col-md-4 card-center col-12" rel="noopener noreferrer">
      <div >
        <div className="card" style={{ width: "18rem" }}>
          <img
            src="Assets/portfolio2.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body card-2">
            <h5 className="card-text">
              Andriod, IOS Development and Custom Development
            </h5>
          </div>
        </div>
      </div>
    </a>
    
    <a href="https://play.google.com/store/apps/details?id=com.triple.tile.match.puzzle.game.three.object.matching&hl=en&gl=US" target="_blank" className="col-md-4 card-center col-12" rel="noopener noreferrer">
    <div >
      <div className="card card-3" style={{ width: "18rem" }}>
        <img
          src="Assets/portfolio3.jpeg"
          className="card-img-top"
          alt="..."
        />
        <div className="card-body">
          <h4 className="card-text">Game Development</h4>
        </div>
      </div>
    </div>
  </a>
  
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
