import React from "react";

function Hero() {
  return (
    <div className="Hero">
      <div className="center-heading">
        <div className="hero-heading">
          <h1><b>CUSTOM SOFTWARE DEVELOPMENT SERVICES</b></h1>
          <h4>
            <b>Transforming Industries with AI-Powered Custom Software Solutions</b>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Hero;
